:root {
  --bs-primary: #005888;
  --bs-table-bg: $bg-light;
}

.btn-primary {
  color: #fff;
  // background: #005888 !important;
  background: radial-gradient(
    farthest-corner at 50% 0px,
    #008fdd 10%,
    #006095 75%
  );
  border: none;
  --bs-btn-color: #fff;
  --bs-btn-bg: #005888;
  --bs-btn-border-color: #005888;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #005888;
  --bs-btn-disabled-border-color: #005888;
}
